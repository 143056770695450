<template>
  <div class="GenericRedirectView" />
</template>

<script>
export default {
    created() {
        this.$router.replace({
            path: this.$route.query.path
        });
    }
};
</script>

<style lang="scss" scoped>
.GenericRedirectView {
    background-color: white;
    padding: 1em;
}
</style>
